<template>
  <div class="flex items-start">
    <div>
      <div class="p-10 overflow-y-auto " ref="document">
        <section class="grid grid-cols-2">
          <div class="col-span-1"></div>
          <div class="col-span-1 flex justify-end">
            <div>
              <img
                style="max-width: 200px"
                src="@/assets/otoride/logo/magnushub.png"
                alt="magnushub"
              />
            </div>
          </div>
        </section>

        <section class="grid grid-cols-2 mt-10">
          <div class="col-span-1">
            <h3 class="font-bold">Bill To</h3>
            <p>{{ orgName }}</p>
            <p>
              {{ orgAddress }}
            </p>
            <p>{{ orgEmail }}</p>
          </div>
          <div class="col-span-1 text-right">
            <div>
              <h3>&nbsp;</h3>
              <div>
                <p>MagnusHub Limited</p>
                <p>2/A Road 1,Niketon, Gulshan</p>
                <p>Dhaka-1212,Bangladesh</p>
              </div>
            </div>
          </div>
        </section>
        <section class="mt-10 grid grid-cols-4">
          <div class="col-span-1">
            <p class="font-bold">Invoice ID</p>
            <p>#INV-{{ invoiceId.toUpperCase() }}</p>
          </div>
          <div class="col-span-1">
            <p class="font-bold">Issue Date</p>
            <p>{{ issueDate }}</p>
          </div>
          <div class="col-span-1">
            <p class="font-bold">Due Date</p>
            <p>{{ dueDate }}</p>
          </div>
          <div class="col-span-1">
            <p class="font-bold">Status</p>
            <p class="font-semibold" :class="getPaidStatusClass">
              {{ paidStatus }}
            </p>
          </div>
        </section>
        <section class="mt-10">
          <div class="grid grid-cols-12 bg-gray-200 px-1 pt-0 pb-3">
            <div class="col-span-5 ">
              <p class="font-bold">Description</p>
            </div>
            <div class="col-span-7 grid grid-cols-7">
              <div class="col-span-2 ">
                <p class="font-bold">Qty</p>
              </div>
              <div class="col-span-2 ">
                <p class="font-bold">Unit Price</p>
              </div>
              <div class="col-span-3 text-right">
                <p class="font-bold">Amount</p>
              </div>
            </div>
          </div>
          <!-- item -->
          <div
            v-for="(item, itemIndex) in invoiceItems"
            :key="itemIndex"
            class="grid grid-cols-12  py-2 px-1 border-b border-gray-200"
          >
            <div class="col-span-5">
              <p>{{ item.description || '' }}</p>
            </div>
            <div class="col-span-7 grid grid-cols-7">
              <div class="col-span-2">
                <p>{{ item.quantity || 0 }}</p>
              </div>
              <div class="col-span-2">
                <p>{{ getFormattedPrice(parseFloat(item.unit_fee) || 0) }}</p>
              </div>
              <div class="col-span-3 text-right">
                <p>
                  {{
                    getFormattedPrice(
                      item.quantity * parseFloat(item.unit_fee) || 0
                    )
                  }}
                </p>
              </div>
            </div>
          </div>

          <div class="grid grid-cols-12  py-2 px-1  ">
            <div class="col-span-5"></div>
            <div
              class="col-span-7  flex justify-between border-b border-gray-200  pb-2"
            >
              <div>
                <p>Subtotal</p>
              </div>
              <div class=" text-right">
                <p>{{ getFormattedPrice(getSubTotalCost) }}</p>
              </div>
            </div>
          </div>
          <div v-if="haveDiscount" class="grid grid-cols-12  py-2 px-1 ">
            <div class="col-span-5"></div>
            <div
              class="col-span-7  flex justify-between border-b border-gray-200 pb-2"
            >
              <div>
                <p>{{ getDiscountTitle }}</p>
              </div>
              <div class=" text-right">
                <p>-{{ getFormattedPrice(getDiscountCost) }}</p>
              </div>
            </div>
          </div>
          <div v-if="vatPercentage" class="grid grid-cols-12  py-2 px-1 ">
            <div class="col-span-5"></div>
            <div
              class="col-span-7  flex justify-between border-b border-gray-200 pb-2"
            >
              <div>
                <p>VAT({{ vatPercentage }}%)</p>
              </div>
              <div class=" text-right">
                <p>{{ getFormattedPrice(getVatAmount) }}</p>
              </div>
            </div>
          </div>
          <div class="grid grid-cols-12  py-1.5 px-1 ">
            <div class="col-span-5"></div>
            <div class="col-span-7  flex justify-between ">
              <div>
                <p class="font-bold">
                  Total <span> {{ paidStatus }} </span>
                </p>
              </div>
              <div class=" text-right">
                <p>{{ currencyCode }} {{ Math.round(getTotalAmountCost) }}</p>
              </div>
            </div>
          </div>
          <div class="text-right mt-3">
            <p class="font-bold">
              In Words: {{ getWords }} {{ currencyCode }} only
            </p>
          </div>
          <div class="mt-16 grid grid-cols-12">
            <div class="col-span-6 flex items-center justify-start">
              <div class="text-xs">
                <h2 class="text-base" style="font-weight: 900;">
                  Bank Information
                </h2>
                <p v-if="bankInfo.bank_name && bankInfo.bank_name !== 'Paypal'">
                  Bank Name: {{ bankInfo.bank_name }}
                </p>

                <p v-if="bankInfo.account_holder_name">
                  Account Holder Name: {{ bankInfo.account_holder_name }}
                </p>
                <p v-if="bankInfo.beneficiary_name">
                  Beneficiary Name: {{ bankInfo.beneficiary_name }}
                </p>
                <p v-if="bankInfo.account_number">
                  Account Number: {{ bankInfo.account_number }}
                </p>
                <p v-if="bankInfo.swift_code">
                  Swift Code: {{ bankInfo.swift_code }}
                </p>
                <p v-if="bankInfo.routing">
                  Routing Number: {{ bankInfo.routing }}
                </p>
                <p v-if="bankInfo.institution_number">
                  Institution Number: {{ bankInfo.institution_number }}
                </p>

                <p v-if="bankInfo.transit_number">
                  Transit Number: {{ bankInfo.transit_number }}
                </p>
                <p v-if="bankInfo.bankCode">
                  Bank Code: {{ bankInfo.bank_code }}
                </p>
                <p v-if="bankInfo.branch_code">
                  Branch Code: {{ bankInfo.branch_code }}
                </p>

                <p v-if="bankInfo.iban">IBAN: {{ bankInfo.iban }}</p>
                <p v-if="bankInfo.bic">BIC: {{ bankInfo.bic }}</p>
                <p v-if="bankInfo.account_type">
                  Account Type: {{ bankInfo.account_type }}
                </p>
                <p v-if="bankInfo.bank_address">
                  Bank Address: {{ bankInfo.bank_address }}
                </p>
                <div v-if="bankInfo.paypal_email">
                  <p>Paypal</p>
                  <p>Email: {{ bankInfo.paypal_email }}</p>
                </div>
              </div>
            </div>
            <div class="col-span-6 flex items-center justify-end">
              <div>
                <img
                  style="max-width: 200px"
                  src="@/assets/img/chairman_signature.png"
                  alt="chairman_signature"
                />
                <p class="text-sm font-semibold text-center">
                  Authorized Signature
                </p>
              </div>
            </div>
          </div>
          <div class="mt-8 " v-if="onlinePaymentLink">
            <div class="col-span-6  ">
              <p class="mb-2">
                <a
                  :href="onlinePaymentLink"
                  class="font-semibold text-xs text-blue-600"
                  target="_blank"
                  >Pay Online</a
                >
              </p>
            </div>
          </div>

          <div class="mt-8 grid grid-cols-12">
            <div class="col-span-3">
              <VueQrcode
                :value="onlinePaymentLink"
                :options="{ width: 58, margin: 0 }"
              />
            </div>
            <div class="col-span-6">
              <div class="  w-full flex justify-center">
                <img
                  style="max-width: 140px"
                  src="@/assets/otoride/logo/otoride.png"
                  alt="otoride"
                />
              </div>
              <p class="text-center text-sm">
                A product of <span class="font-bold">MagnusHub Ltd</span>
              </p>
            </div>
            <div class="col-span-3"></div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import html2pdf from 'html2pdf.js'
import { ToWords } from 'to-words'
import { EventBus } from '@/utils'
import VueQrcode from '@chenfengyuan/vue-qrcode'
export default {
  name: 'DynamicLocalInvoice',
  props: {
    invoiceId: {
      type: String,
    },
    issueDate: {
      type: String,
    },
    orgName: {
      type: String,
    },
    orgAddress: {
      type: String,
    },
    orgEmail: {
      type: String,
    },
    dueDate: {
      type: String,
    },
    currencySymbol: {
      type: String,
    },
    currencyCode: {
      type: String,
    },
    invoiceItems: {
      type: Array,
      default: () => [],
    },
    bankInfo: {
      type: Object,
    },
    discountType: {
      type: String,
    },

    discountValue: {
      type: Number,
    },
    vatPercentage: {
      type: Number,
    },
    paidStatus: {
      type: String,
    },
    onlinePaymentLink: {
      type: String,
    },
  },
  components: {
    VueQrcode,
  },
  mounted() {
    EventBus.$on('subscription-invoice-download', () => {
      this.exportToPDF()
    })
  },
  computed: {
    getPaidStatusClass() {
      if (this.paidStatus === 'DUE') {
        return 'text-red-500'
      }
      if (this.paidStatus === 'PAID') {
        return 'text-green-500'
      }
      return ''
    },
    getSubTotalCost() {
      if (this.invoiceItems.length) {
        const total = this.invoiceItems.reduce((sum, item) => {
          return (sum += item.quantity * parseFloat(item.unit_fee))
        }, 0)
        return total
      }
      return 0
    },
    getDiscountTitle() {
      if (this.discountType === 'FLAT' && this.discountValue) {
        return `Discount`
      }
      if (this.discountType === 'RATE' && this.discountValue) {
        return `Discount(${this.discountValue}% OFF)`
      }
      return 'Discount'
    },
    getDiscountCost() {
      if (this.discountType === 'FLAT' && this.discountValue) {
        return this.discountValue
      }
      if (this.discountType === 'RATE' && this.discountValue) {
        const discount = (this.getSubTotalCost * this.discountValue) / 100
        return discount
      }
      return 0
    },
    haveDiscount() {
      if (this.discountType === 'NONE') {
        return false
      } else {
        return true
      }
    },
    getVatAmount() {
      const vatApplicableCost = this.getSubTotalCost - this.getDiscountCost
      return (vatApplicableCost * this.vatPercentage) / 100
    },
    getTotalAmountCost() {
      const total = this.getSubTotalCost + this.getVatAmount
      const leftCost = total - this.getDiscountCost
      return leftCost || 0
    },
    getWords() {
      const toWords = new ToWords()
      return toWords.convert(parseFloat(Math.round(this.getTotalAmountCost)))
    },
  },
  methods: {
    getFormattedPrice(price) {
      if (price) {
        return `${this.currencySymbol}${price.toFixed(2)}`
      } else {
        return `${this.currencySymbol}${0.0}`
      }
    },
    exportToPDF() {
      html2pdf(this.$refs.document, {
        margin: 0,
        filename: `${this.orgName
          .replace(' ', '_')
          .toUpperCase()}_INV-${this.invoiceId.toUpperCase()}.pdf`,
        image: { type: 'jpeg', quality: 0.5 },
        html2canvas: {
          scale: 2,
          useCORS: true,
        },
        jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait' },
      })
    },
  },
}
</script>
<style lang="scss"></style>
