<template>
  <slide-over-right :id="sorId" :fullWidth="true" v-on:showModal="showModal">
    <loading :active.sync="isLoading"></loading>

    <div class="flex items-center pl-8 my-1 md-max:pl-4">
      <div
        class="items-center justify-center cursor-pointer back-button"
        @click="goBack"
      >
        <i class="fas fa-arrow-left"></i>
      </div>
      <h2 class="ml-3 font-semibold text-gray-900 text-22px">
        {{ titleText }}
      </h2>
    </div>
    <div class="px-6 grid grid-cols-2">
      <div class="col-span-1 ">
        <div class="overflow-y-auto" style="height: calc(100vh - 90px )">
          <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
            <form
              @submit.prevent="handleSubmit(confirmSubmit)"
              enctype="multipart/form-data"
            >
              <div class="flex items-start gap-2">
                <div class="w-1/2">
                  <AppInput
                    rules="required"
                    name="Issue Date"
                    label="Issue Date"
                    type="date"
                    :isInfoEnabled="false"
                    v-model="formData.issue_date"
                  />
                </div>
                <div class="w-1/2">
                  <AppInput
                    rules="required"
                    name="Due Date"
                    label="Due Date"
                    type="date"
                    :isInfoEnabled="false"
                    v-model="formData.due_date"
                  />
                </div>
              </div>
              <div class="flex items-start gap-2">
                <div class="w-1/2">
                  <AppInput
                    v-model="formData.currency"
                    type="richselect"
                    :isInfoEnabled="false"
                    label="Currency"
                    value-attribute="id"
                    text-attribute="name"
                    placeholder="Select a Currency"
                    :options="currencies"
                    hide-search-box
                  />
                </div>
                <div class="w-1/2">
                  <AppInput
                    rules="required"
                    name="VAT(%)"
                    label="VAT(%)"
                    type="number"
                    :isInfoEnabled="false"
                    v-model="formData.vat_percentage"
                  />
                </div>
              </div>
              <div>
                <AppInput
                  v-model="formData.status"
                  type="richselect"
                  :isInfoEnabled="false"
                  label="Status"
                  value-attribute="value"
                  text-attribute="text"
                  placeholder="Select a status"
                  :options="statusTypes"
                  hide-search-box
                />
              </div>

              <div class="flex items-center mb-3 font-bold text-gray-500">
                <span>Payment</span>
              </div>
              <div>
                <AppInput
                  v-model="formData.bank"
                  type="richselect"
                  :isInfoEnabled="false"
                  label="Choose Bank"
                  value-attribute="id"
                  text-attribute="displayText"
                  placeholder="Select a bank"
                  :options="bankAccounts"
                  hide-search-box
                />
              </div>
              <div>
                <TCheckbox
                  v-model="extraData.useOnlinePayment"
                  name="Use Online Payment"
                  label="Use Online Payment"
                  wrapped
                />
                <AppInput
                  v-if="extraData.useOnlinePayment"
                  rules="required"
                  name="Online Payment Link"
                  label="Online Payment Link"
                  type="text"
                  :isInfoEnabled="false"
                  v-model="formData.online_payment_link"
                />
              </div>
              <div class="flex items-center mb-3 font-bold text-gray-500">
                <span>Discount</span>
              </div>
              <div>
                <AppInput
                  v-model="formData.discount_type"
                  type="richselect"
                  :isInfoEnabled="false"
                  label="Choose Discount Type"
                  value-attribute="value"
                  text-attribute="text"
                  placeholder="Select a type"
                  :options="discountTypes"
                  hide-search-box
                />
              </div>
              <div v-if="formData.discount_type === 'FLAT'">
                <AppInput
                  rules="required"
                  :name="`Flat Amount(${getCurrency.symbol})`"
                  :label="`Flat Amount(${getCurrency.symbol})`"
                  type="number"
                  :isInfoEnabled="false"
                  v-model="formData.flat_discount_amount"
                />
              </div>
              <div v-if="formData.discount_type === 'RATE'">
                <AppInput
                  rules="required"
                  :name="`Discount Percentage(%)`"
                  :label="`Discount Percentage(%)`"
                  type="number"
                  :isInfoEnabled="false"
                  v-model="formData.rate_discount_percentage"
                />
              </div>
              <div>
                <TCheckbox
                  v-model="extraData.is_minimum_fee_applied"
                  name="Apply Minimum Fee"
                  label="Apply Minimum Fee"
                  wrapped
                  @change="onChangeMinimumFeeApplied"
                />
              </div>

              <div class=" mt-3  ">
                <section>
                  <div class="flex items-center mb-3 font-bold text-gray-500">
                    <span>Manage Invoice Items</span>
                    <div
                      class="col-span-1 ml-2 cursor-pointer focus:text-gray-400"
                      @click="onAddInvoiceItem"
                    >
                      <i class="fas fa-plus-circle" style="color: black"></i>
                    </div>
                  </div>

                  <!-- {{ invoice items }} -->
                  <div
                    class="flex items-center my-1"
                    v-for="(item, itemIndex) in getInvoiceItems"
                    :key="itemIndex"
                  >
                    <div class="flex gap-2 items-center w-11/12">
                      <div class="w-6/12">
                        <AppInput
                          rules="required"
                          name="Description"
                          label="Description"
                          type="text"
                          :isInfoEnabled="false"
                          v-model="item.description"
                        />
                      </div>

                      <div class="w-3/12">
                        <AppInput
                          rules="required"
                          name="Quantity"
                          label="Quantity"
                          type="number"
                          :isInfoEnabled="false"
                          v-model="item.quantity"
                        />
                      </div>

                      <div class="w-3/12">
                        <AppInput
                          rules="required"
                          :name="`Unit Price(${getCurrency.symbol})`"
                          :label="`Unit Price(${getCurrency.symbol})`"
                          type="number"
                          :isInfoEnabled="false"
                          v-model="item.unit_fee"
                        />
                      </div>
                    </div>

                    <div
                      class="flex justify-center w-1/12 item-center"
                      v-if="!item.is_original_item"
                    >
                      <div
                        class="col-span-1 cursor-pointer focus:text-gray-400"
                        @click="onRemoveInvoiceItem(itemIndex)"
                      >
                        <i
                          class="fas fa-minus-circle"
                          style="color: #d90a20"
                        ></i>
                      </div>
                    </div>
                  </div>
                </section>
              </div>

              <button type="submit" ref="submitButton" class="hidden">
                Save
              </button>
            </form>
          </ValidationObserver>
        </div>
        <div>
          <div class="flex justify-end items-center pr-2 mt-1.5">
            <slide-over-right-trigger :id="sorId">
              <anchor-button variant="secondary">Cancel</anchor-button>
            </slide-over-right-trigger>
            <anchor-button
              variant="warning-alt"
              class="ml-3"
              @click="downloadPdf"
              >Download</anchor-button
            >
            <t-button type="submit" @click="submit()" class="ml-3"
              >Save</t-button
            >
          </div>
        </div>
      </div>
      <div
        class="col-span-1 py-5 px-10 overflow-y-auto"
        style="height: calc(100vh - 50px )"
      >
        <div
          class="w-full h-full  overflow-auto "
          style=" box-shadow: 5px 5px 20px 5px  #3134692c,-5px -5px 20px 5px #3134692c;"
        >
          <DynamicLocalInvoice
            :invoiceId="$truncate(formData.id, -5)"
            :issueDate="getDate(`${this.formData.issue_date}T00:00:00`)"
            :dueDate="getDate(`${this.formData.due_date}T00:00:00`)"
            :invoiceItems="getInvoiceItems"
            :currencySymbol="getCurrency.symbol"
            :currencyCode="getCurrency.name"
            :bankInfo="getBankInfo"
            :discountType="formData.discount_type"
            :discountValue="parseFloat(getDiscountValue)"
            :vatPercentage="parseFloat(formData.vat_percentage)"
            :orgName="orgDetails.name || ''"
            :orgAddress="orgDetails.address || ''"
            :orgEmail="orgDetails.email_address || ''"
            :paidStatus="getPaidStatus"
            :onlinePaymentLink="formData.online_payment_link"
          />
        </div>
      </div>
    </div>
  </slide-over-right>
</template>
<script>
import { OrgInvoiceConfig } from '@/config/OrgInvoiceConfig.js'
// import { EventBus } from '@/utils/EventBus'
// import xMan from '@/utils/xMan'
import SlideOverRight from '@/components/modals/SlideOverRight'
// import TextInput from '@/components/form/TextInput'
import AnchorButton from '@/components/form/AnchorButton'

import SlideOverRightTrigger from '@/components/modals/SlideOverRightTrigger'
import DynamicLocalInvoice from '@/composites/organization/details/DynamicLocalInvoice.vue'

import { EventBus } from '@/utils'
import { useEndpoints } from '@/composables'
import { getFormattedDateTime } from '@/utils/datetime'
export default {
  name: 'OrgInvoiceEdit',
  components: {
    SlideOverRight,
    AnchorButton,
    SlideOverRightTrigger,
    DynamicLocalInvoice,
  },
  props: {
    isInitDataLoading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      sorId: OrgInvoiceConfig.events.sorId,
      isLoading: false,
      formData: {},
      extraData: {},
      deleteSubscriptionInvoiceItems: [],
      previousSubscriptionItems: {
        description: '',
        quantity: 0,
        unit_fee: 0,
      },
      orgDetails: {},

      currency: 'USD',
      bankAccount: 'SINGAPORE_SWIFT',
      discountType: null,
      discountValue: 0,
      paidStatus: 'D',
      usefulCurrencies: ['USD', 'CAD', 'EUR'],
      currencies: [],
      statusTypes: [
        {
          text: 'PAID',
          value: 'P',
        },
        {
          text: 'DUE',
          value: 'U',
        },
      ],
      bankAccounts: [
        {
          id: 'EUROPEAN_BANK',
          displayText: 'European Bank',
        },
        {
          id: 'USA_BANK',
          displayText: 'USA Bank',
        },
        {
          id: 'CANADIAN_BANK',
          displayText: 'Canadian Bank',
        },
        {
          id: 'BD_BANK',
          displayText: 'BD Bank',
        },
        {
          id: 'SINGAPORE_SWIFT',
          displayText: 'Singapore - SWIFT',
        },
        {
          id: 'PAYPAL_USA',
          displayText: 'Paypal(USA)',
        },
        {
          id: 'PAYPAL_CANADA',
          displayText: 'Paypal(Canada)',
        },
        {
          id: 'OTHER_BANK',
          displayText: 'Other',
        },
      ],
      bankAccountDetails: {},
      discountTypes: [
        {
          text: 'None',
          value: 'NONE',
        },
        {
          text: 'Flat',
          value: 'FLAT',
        },
        {
          text: 'Percentage',
          value: 'RATE',
        },
      ],
    }
  },
  async created() {
    this.orgDetails = await this.$http
      .get(useEndpoints.organization.details(this.getOrgId))
      .then((res) => res.data)
      .catch((err) => console.log(err))
    console.log('orgDetails', this.orgDetails)
  },
  computed: {
    isEditing: function() {
      return !!this.formData.id
    },
    titleText() {
      return this.isEditing ? `Edit Invoice` : ``
    },
    getOrgId() {
      return this.$route.params.id
    },
    getCurrency() {
      if (this.formData.currency) {
        const selectedCurrency = this.currencies.find(
          (item) => item.id === this.formData.currency
        )
        return selectedCurrency
      } else {
        return {
          symbol: '$',
          name: 'USD',
        }
      }
    },
    getPaidStatus() {
      if (this.formData.status) {
        const selectedStatus = this.statusTypes.find(
          (item) => item.value === this.formData.status
        )
        return selectedStatus.text
      } else {
        return ''
      }
    },
    getInvoiceItems() {
      return this.formData.items
    },
    getBankInfo() {
      const selectedBank = this.bankAccountDetails[this.formData.bank]
      return selectedBank
    },
    getDiscountValue() {
      if (this.formData.discount_type === 'FLAT') {
        return this.formData.flat_discount_amount
      }
      if (this.formData.discount_type === 'RATE') {
        return this.formData.rate_discount_percentage
      }
      return 0
    },
  },
  mounted() {
    EventBus.$on(OrgInvoiceConfig.events.editingData, (data) => {
      console.log('iv-e', data)
      this.formData = data.formData
      this.extraData = data.extraData
      this.currencies = data.extraData.currencies.filter((ci) => {
        if (this.usefulCurrencies.includes(ci.name)) {
          return ci
        }
      })
      this.bankAccountDetails = this.extraData.bankDetails

      this.deleteSubscriptionInvoiceItems = []
      this.previousSubscriptionItems.description =
        data.formData.items[0].description

      this.previousSubscriptionItems.quantity = data.formData.items[0].quantity
      this.previousSubscriptionItems.unit_fee = data.formData.items[0].unit_fee
    })
  },
  methods: {
    getFormattedDateTime,
    downloadPdf() {
      EventBus.$emit('subscription-invoice-download')
    },
    getDate(date) {
      var time = new Date(date)

      var month = time.getMonth()
      var days = time.getDate()
      var year = time.getFullYear()
      var arr_month = new Array()
      arr_month[0] = 'January'
      arr_month[1] = 'February'
      arr_month[2] = 'March'
      arr_month[3] = 'April'
      arr_month[4] = 'May'
      arr_month[5] = 'June'
      arr_month[6] = 'July'
      arr_month[7] = 'August'
      arr_month[8] = 'September'
      arr_month[9] = 'October'
      arr_month[10] = 'November'
      arr_month[11] = 'December'

      return days + ' ' + arr_month[month] + ' , ' + year
    },
    goBack() {
      dispatchEvent(new Event(OrgInvoiceConfig.events.sorToggle))
    },
    onAddInvoiceItem() {
      this.formData.items.push({ description: '', quantity: 0, unit_fee: 0 })
    },
    onRemoveInvoiceItem(index) {
      if (index === 0) return

      if (this.formData.items[index].id) {
        this.deleteSubscriptionInvoiceItems.push(this.formData.items[index])
      }

      this.formData.items.splice(index, 1)
    },
    onChangeMinimumFeeApplied(value) {
      if (value) {
        this.formData.items[0].description = `${this.formData.items[0].description} - Minimum`
        this.formData.items[0].quantity = 1
        this.formData.items[0].unit_fee = this.extraData.minimum_fee
        this.formData.items[0].is_minimum_fee_applied = true
      } else {
        this.formData.items[0].description = this.previousSubscriptionItems.description
        this.formData.items[0].quantity = this.previousSubscriptionItems.quantity
        this.formData.items[0].unit_fee = this.previousSubscriptionItems.unit_fee
        this.formData.items[0].is_minimum_fee_applied = false
      }
    },
    showModal() {
      dispatchEvent(new Event(OrgInvoiceConfig.events.sorToggle))
    },

    submit() {
      this.$refs.submitButton.click()
    },
    async confirmSubmit() {
      console.log('confirmSubmit', this.formData)

      this.isLoading = true

      let subscriptionInvoiceItemRequests = []
      if (this.formData.items.length) {
        this.formData.items.forEach((element, index) => {
          const isUpdateReq = 'id' in element
          console.log('isUpdateReq', isUpdateReq)
          const subscriptionInvoiceItemReqMethod = isUpdateReq
            ? 'PATCH'
            : 'POST'
          const subscriptionInvoiceItemReqUrl = isUpdateReq
            ? useEndpoints.organization.subscriptionInvoice.updateInvoiceItem(
                element.id
              )
            : useEndpoints.organization.subscriptionInvoice.createInvoiceItem()

          const subscriptionInvoiceItemData = new FormData()
          if (!isUpdateReq) {
            subscriptionInvoiceItemData.append(
              'subscription_invoice',
              this.formData.id
            )
          }

          if (index === 0) {
            subscriptionInvoiceItemData.append(
              'is_minimum_fee_applied',
              this.formData.items[0].is_minimum_fee_applied
                ? this.formData.items[0].is_minimum_fee_applied
                : false
            )
          }

          subscriptionInvoiceItemData.append('description', element.description)
          subscriptionInvoiceItemData.append('quantity', element.quantity)
          subscriptionInvoiceItemData.append('unit_fee', element.unit_fee)

          const subscriptionInvoiceItemReq = this.$http({
            url: subscriptionInvoiceItemReqUrl,
            method: subscriptionInvoiceItemReqMethod,
            data: subscriptionInvoiceItemData,
          })
          subscriptionInvoiceItemRequests.push(subscriptionInvoiceItemReq)
        })
      }
      this.deleteSubscriptionInvoiceItems.forEach((element) => {
        const subscriptionInvoiceItemReq = this.$http({
          url: useEndpoints.organization.subscriptionInvoice.deleteInvoiceItem(
            element.id
          ),
          method: 'DELETE',
        })
        subscriptionInvoiceItemRequests.push(subscriptionInvoiceItemReq)
      })

      await this.$http
        .all(subscriptionInvoiceItemRequests)
        .then(() => {
          let data = new FormData()
          data.append('issue_date', `${this.formData.issue_date}T00:00:00`)
          data.append('due_date', `${this.formData.due_date}T00:00:00`)
          data.append('vat_percentage', this.formData.vat_percentage)
          data.append('currency', this.formData.currency)
          data.append('status', this.formData.status)
          data.append('bank', this.formData.bank)

          if (this.extraData.useOnlinePayment) {
            data.append(
              'online_payment_link',
              this.formData.online_payment_link
            )
          } else {
            data.append('online_payment_link', '')
          }
          data.append('discount_type', this.formData.discount_type)

          if (this.formData.discount_type === 'FLAT') {
            data.append(
              'flat_discount_amount',
              this.formData.flat_discount_amount
            )
          }
          if (this.formData.discount_type === 'RATE') {
            data.append(
              'rate_discount_percentage',
              this.formData.rate_discount_percentage
            )
          }

          this.$http
            .patch(
              useEndpoints.organization.subscriptionInvoice.updateInvoice(
                this.formData.id
              ),
              data
            )
            .then(() => {
              this.$notify({
                group: 'bottomLeft',
                type: 'success',
                title: 'Success',
                text: 'Successfully Updated',
              })
              this.$emit('refresh')
              dispatchEvent(new Event(OrgInvoiceConfig.events.sorToggle))
              dispatchEvent(new Event(OrgInvoiceConfig.events.refresh))
            })
            .catch((err) => {
              this.$notify({
                group: 'bottomLeft',
                type: 'error',
                title: 'Error occurred!',
                text: err.response.data.detail,
              })
            })
            .finally(() => (this.isLoading = false))
        })
        .catch((err) => {
          console.error('Error occurred!', err)
          this.$notify({
            group: 'bottomLeft',
            type: 'error',
            title: 'Error occurred!',
            text: 'Failed to save',
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
<style lang="scss">
.header-title {
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
</style>
